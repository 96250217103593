import React from 'react';
import DrawTable from './draw-table';
import {aspects, functions, reinins} from './socioData';
import data from './jsons/simple';
import {contentType} from "./Const";

class SimplePage extends React.Component {


    render() {

        let aspTable = <div>
            <h5 className="mx-3">Ознаки Аспектів</h5>
            <DrawTable data={data.aspects} idx={0} dichotomies={aspects} type={contentType.aspects} key={0}
                       showId={false}/>
        </div>;
        let funTable = <div>
            <h5 className="mx-3">Ознаки Функцій</h5>
            <DrawTable data={data.functions} idx={0} dichotomies={functions} type={contentType.functions} key={0}
                       showId={false}/>
        </div>;
        let reiTable = <div>
            <h5 className="mx-3">Ознаки Рейніна</h5>
            <DrawTable data={data.reinins} idx={0} dichotomies={reinins} type={contentType.reinins} key={0}
                       showId={false}/>
        </div>;

        return <div>
            <div className="d-flex">
                {aspTable}
                {funTable}
            </div>
            <div className="d-flex">
                {reiTable}
            </div>
        </div>

    }

}

export default SimplePage;