import React from 'react';
import './App.css';
import VisibilitySensor from 'react-visibility-sensor';
import {contentType, compareNumbers } from "./Const";
import tooltip from "./tooltips";
import {types, functions} from "./socioData";


class DrawTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            temp: true
        };
    }


    render() {

        let {data, idx, dichotomies, type, showId = true} = this.props;

        let columnWidthAspects = "px-2";
        let columnWidthFunctions = "px-2";
        let columnWidthReinin = "px-2";

        let FLItemTooltip = (value, style, tipType, tipParam) => {
            return <th className={style} data-tip data-for={tipType+tipParam}>
                {value}
                {tooltip(tipType,tipParam)}
            </th>
        };

        let firstLineAspects = (<thead><tr>
            <th className="text-center small text-secondary">+/-</th>
            {FLItemTooltip("ЧС", columnWidthAspects, "aspects","name0")}
            {FLItemTooltip("БС", columnWidthAspects, "aspects","name1")}
            {FLItemTooltip("БЛ", columnWidthAspects, "aspects","name2")}
            {FLItemTooltip("ЧЛ", columnWidthAspects, "aspects","name3")}
            {FLItemTooltip("ЧЕ", columnWidthAspects, "aspects","name4")}
            {FLItemTooltip("БЕ", columnWidthAspects, "aspects","name5")}
            {FLItemTooltip("БІ", columnWidthAspects, "aspects","name6")}
            {FLItemTooltip("ЧІ", columnWidthAspects, "aspects","name7")}
            <th className={columnWidthAspects} /></tr>
        </thead>);

        let ff = [0,1,2,3,4,5,6,7];

        let firstLineFunctions = (<thead><tr>
            <th className="text-center small text-secondary">+/-</th>
            {ff.map((f,idx) => FLItemTooltip("Ф"+ (idx+1), columnWidthFunctions, "functions", idx))}
            <th/></tr>
        </thead>);

        let firstLineReinins = (<thead>
        <tr>
            <th className="text-center small text-secondary">+/-</th>
            {types.map(t => FLItemTooltip(t.code, columnWidthReinin, "types", t.id))}
            <th className={columnWidthReinin}/>
        </tr>
        </thead>);


        let firstLineRender =
            type === contentType.reinins ? firstLineReinins :
                type === contentType.aspects ? firstLineAspects : firstLineFunctions;

        let errorLine = (n,k,i) => {
            let line = [];
            for (let idx = 0; idx < n; idx++)
            line.push(<td key={idx} />)

            return (<tr key={"error"+k+i}>
            <td><b className="redText">Помилка обчислення!</b></td>
            {line}
            <td>{k}</td>
        </tr>)};



        let tableRender = (data, idx) => {

            let code = [];

            code.push(data.x1 + 1);
            code.push(data.x2 + 1);
            code.push(data.x3 + 1);
            if (type === contentType.reinins)
                code.push(data.x4 + 1);

            code.sort(compareNumbers);

            let tableId = idx + 1;
            let itemsCodes = code.join();

            let tempTable = <VisibilitySensor
                partialVisibility={true}
                offset={{top: -50, bottom: -50}}
                active={this.state.temp}
                onChange={(isVisible) => {

                    if (isVisible) {
                        //console.log("visible ", idx, isVisible);
                        this.setState({temp: false});
                    }
                }}
            >
                <table className={type === contentType.reinins ? "tempTable15" : "tempTable7"}>
                    <tbody>
                    <tr>
                        <td>
                            <div>Loading..</div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </VisibilitySensor>;

            let completeTable = <table className="tableStyle table table-striped table-bordered" key={tableId}>
                {firstLineRender}
                <tbody>
                {
                    Object.keys(data).map((k, i) => {

                        if (data[k] >= 0) {  // !== "error"

                            let dName = dichotomies[data[k]].name;

                            let isDuplicate = Object.keys(data)
                                .map(dk => data[dk] !== undefined ? data[dk] : "error")
                                .indexOf(data[k])  < i;

                            return (
                                <tr key={"line" + k + i}>
                                    <td key={"name" + dName}>
                                        {(i >= (type === contentType.reinins ? 4 : 3)) ?
                                            (isDuplicate? (<i>{dName}</i>) : dName) : (<b>{dName}</b>)}
                                    </td>
                                    {
                                        dichotomies[data[k]].value.map((v, index) => {
                                            return (
                                                <td key={"cell" + idx + k + i + v + index}
                                                    className="text-center">
                                                    {v === 1 ? "+" : "-"}
                                                </td>)
                                        })
                                    }
                                    <td>{k}</td>
                                </tr>
                            )
                        }
                        else
                            return errorLine( type === contentType.reinins ? 16 : 8, k,i);
                    })
                }
                </tbody>
            </table>;

            let idLabel = <p><b>#{tableId}</b> <span className="text-secondary">({itemsCodes})</span></p>;

            return (
                <div className="tableContainer" key={tableId}>
                    { showId  ? idLabel : null }
                    { this.state.temp ? tempTable : completeTable }
                </div>
            );
        };

        return (tableRender(data,idx));
    };

}


export default DrawTable;