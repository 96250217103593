import React, { Suspense, lazy }  from 'react';
import './App.css';

import {contentList, contentType, log} from './Const';
import { Button, Modal } from 'react-bootstrap';
import SimplePage from './simplePage';

const AdvancedPage = lazy(() => import('./advancedPage'));

const context = require.context('./jsons', true, /.json$/);
const cachedData = {};

context.keys().forEach((key: any) => {
    const fileName = key.replace('./', '');
    const resource = require(`./jsons/${fileName}`);
    const namespace = fileName.replace('.json', '');
    cachedData[namespace] = JSON.parse(JSON.stringify(resource));

});


if (log)
    console.log("jsons:", cachedData);


class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentType: contentType.aspects,
            currentList: contentList.correct,
            showInfo: false,
            simple: true
        };
    }

    render() {

        let advancedCheck = <form className="mx-3">
            <input type="checkbox" defaultChecked={!this.state.simple} onChange={()=>{ this.setState({simple: !this.state.simple}); }} />
            <label className="small mx-1">Розширено</label>
        </form>;

        let captionRender = (<div className="d-flex align-items-center">
            <h3>Соціоніка: Базис</h3>
            <a href="#" className="mx-3 mb-1" onClick={()=>{ this.setState({showInfo: true})}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                 className="bi bi-question-circle" viewBox="0 0 18 18">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path
                    d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
            </svg></a>
            {advancedCheck}
        </div>);

        let menuRender = (
            <div className="my-2">
                <Button onClick={() => {
                    this.setState({
                        currentType: contentType.aspects
                    })
                }} className="me-2" variant={this.state.currentType === contentType.aspects ? "primary" : "outline-primary" }>Аспекти</Button>
                <Button onClick={() => {
                    this.setState({
                        currentType: contentType.functions
                    })
                }} className="me-2" variant={this.state.currentType === contentType.functions ? "primary" : "outline-primary" }>Функції</Button>
                <Button onClick={() => {
                    this.setState({
                        currentType: contentType.reinins
                    })
                }} className="me-4" variant={this.state.currentType === contentType.reinins ? "primary" : "outline-primary" }>Ознаки Рейніна</Button>
                <Button onClick={() => {
                    this.setState({
                        currentList: contentList.correct
                    })
                }} className="me-2" variant={this.state.currentList === contentList.correct ? "secondary" : "outline-secondary" }>Коректні</Button>
                <Button onClick={() => {
                    this.setState({
                        currentList: contentList.wrong
                    })
                }} className="me-2" variant={this.state.currentList === contentList.wrong ? "secondary" : "outline-secondary" }>Помилкові</Button>
                <Button onClick={() => {
                    this.setState({
                        currentList: contentList.all
                    })
                }} className="me-4" variant={this.state.currentList === contentList.all ? "secondary" : "outline-secondary" }>Усі</Button>
            </div>
        );

        let cacheKey = this.state.currentType+this.state.currentList;
        let currentCache = Object.keys(cachedData).find(k => k === cacheKey) ? cachedData[cacheKey] : null;

        let subPageRender =
                (<AdvancedPage currentType={this.state.currentType} currentList={this.state.currentList} currentCache={currentCache} />);

        let footerRender = (
            <div className="footer">
                <p>(c) <a href="https://fb.com/ilyxa3d" target="_black">Ілля Кравченко</a>, 2021</p>
            </div>
        );


        let handleCloseModal = () => {
            this.setState({showInfo: false});
        };

        let infoModal = (
            <Modal show={this.state.showInfo} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Справка</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><i>"Лише теорія вирішує, що саме можна спостерігати."</i> - А.Ейнштейн</p>
                    <p>У Соціоніку кожен Аспект і Функція, кожен Тип Інформаційного Метаболізму може бути представлений за допомогою мінімального Базису з 3 і 4 дихотомій, відповідно. Щоб бути базовими, ці дихотомії мають бути незалежними одна від одної. Як, наприклад, осі X-Y-Z для опису фізичного простору.</p>
                    <p>Але, досить часто, соціоніки припускаються помилки, і використовують помилковий базис для визначення аспекту, функції, типу. Це призводило до того, що, висунута гіпотеза ТІМу, не може бути перевірена іншими дихотоміями з Ознак Рейніна, тому що не існує математично коректної комбінації з помилкового базису, яка б вказувала на обрану для перевірки дихотомію. Простіше кажучи, обрані дихотомії для складання гіпотези, та обрані для її перевірки, не є частиною однієї теорії, а знаходяться в різному просторі оцінки явищ, логічно та комбінаторно не пов'язані.</p>
                    <p>
                        Існує 28 коректних та 7 помилкових базисів аспектів та функцій; 840 корективних та 525 хибних базисів ТІМу.
                    </p>
                    <p>На цьому сайті ви можете знайти свій улюблений метод типування, 3-4 перші критерії, які ви використовуєте для визначення гіпотези. Якщо він опиниться у списку хибних Базисів, то вам слід звернути увагу на те, що насправді обраний базис не формує повного набору дихотомічних властивостей аспекту/функції/типу, для повноцінної оцінки явища (рядки або стовпчики будуть дублюватися). І якщо ви продовжуєте його використовувати, гіпотеза аспекту/функції/типу багато в чому залежатиме від вашого упередження, бажання, а не від результату аналізу інформації соціонічними методами.</p>

                    <p>На додаток, хочу звернути вашу увагу на те, що представлені варіанти базисів наочно показують нам, як аспект/функція/тип можуть бути описані десятками-сотнями математично коректними способами. Що, у свою чергу, натякає на те, що типувати можна дуже по-різному, і це нормально. У результаті, якщо предмет вивчення збігається (а це: особливості інформаційного метаболізму) суперечки у версії типу не повинно бути.
                    </p>
                    <p>
                        <a href="https://fb.com/ilyxa3d" target="_black">Ілля Кравченко</a>
                    </p>
                </Modal.Body>
            </Modal>
        );

        let advancedPage =
            <Suspense fallback={(<p><b>Зачекайте трохи, дані завантажуються..</b></p>)}>
                { this.state.currentType && this.state.currentList ? subPageRender : (<p>Виберіть розділ та тип даних</p>)}
            </Suspense>;

        let simplePage = <SimplePage/>;

        return (
            <div  className="mainContainer">
                {infoModal}
                {captionRender}
                {this.state.simple ? null : menuRender}
                {this.state.simple ? simplePage : advancedPage}
                {footerRender}
            </div>
        );
    }
}


export default App;
