import React from 'react';
import ReactTooltip from "react-tooltip";


const texts = {

    aspects: {
        name0 : "Чорна Сенсорика (Вольова Сенсорика)",
        name1 : "Біла Сенсорика (Сенсорика Комфорту)",
        name2 : "Біла Логіка (Структурна Логіка)",
        name3 : "Чорна Логіка (Ділова Логіка)",
        name4 : "Чорна Етика (Етика Емоцій)",
        name5 : "Біла Етика (Етика Відносин)",
        name6 : "Біла Інтуїція (Інтуїція Часу)",
        name7 : "Чорна Інтуїція (Інтуїція Можливостей)"
    },
    functions: {
        0: "Базова",
        1: "Творча",
        2: "Рольова",
        3: "Больова",
        4: "Навіювана",
        5: "Активаційна",
        6: "Обмежувальна",
        7: "Фонова"
    },
    types: {
        0: "Шукач",
        1: "Посередник",
        2: "Ентузіаст",
        3: "Аналітик",
        4: "Наставник",
        5: "Інспектор",
        6: "Маршал",
        7: "Лірик",
        8: "Політик",
        9: "Критик",
        10: "Підприємець",
        11: "Хранитель",
        12: "Адміністратор",
        13: "Гуманіст",
        14: "Порадник",
        15: "Майстер"
    }
};


const tooltip = (type, param) => {

    let code = type + "" + param;

    return (
        <ReactTooltip id={code} place="top" effect="solid">
            {texts[type][param]}
        </ReactTooltip>
    );
};

export default tooltip;