
let aspects = [
    {name: "Конкретний Абстрактний", value: [1, 1, 1, 1, 0, 0, 0, 0]},
    {name: "Статичний Динамічний", value: [1, 0, 1, 0, 0, 1, 0, 1]},
    {name: "Зовнішній Внутрішній", value: [1, 0, 0, 1, 1, 0, 0, 1]},
    {name: "Рушійний Інерційний", value: [1, 0, 1, 0, 1, 0, 1, 0]},
    {name: "Виконавчий Цілестворюючий", value: [1, 0, 0, 1, 0, 1, 1, 0]},
    {name: "Сприймаючий Судящий", value: [1, 1, 0, 0, 0, 0, 1, 1]},
    {name: "Залучений Сторонній", value: [1, 1, 0, 0, 1, 1, 0, 0]}
];

let functions = [
    {name: "Ментальна Вітальна", value: [1, 1, 1, 1, 0, 0, 0, 0]},
    {name: "Шаблонна Ситуативна", value: [1, 0, 1, 0, 0, 1, 0, 1]},
    {name: "Завантажена Розвантажена", value: [1, 0, 0, 1, 1, 0, 0, 1]},
    {name: "Акцептна Продуктивна", value: [1, 0, 1, 0, 1, 0, 1, 0]},
    {name: "Інертна Контактна", value: [1, 0, 0, 1, 0, 1, 1, 0]},
    {name: "Сильна Слабка", value: [1, 1, 0, 0, 0, 0, 1, 1]},
    {name: "Вербальна Невербальна", value: [1, 1, 0, 0, 1, 1, 0, 0]}
];

let reinins = [
    {name: "Інтуїт Сенсорик", value: [1, 0, 0, 1, 1, 0, 0, 1, 0, 1, 1, 0, 0, 1, 1, 0]},
    {name: "Логік Етик", value: [1, 0, 0, 1, 0, 1, 1, 0, 0, 1, 1, 0, 1, 0, 0, 1]},
    {name: "Статик Динамік", value: [1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0]},
    {name: "Екстраверт Інтроверт", value: [1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0]},
    // kavadra
    {name: "Демократ Аристократ", value: [1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 1, 0, 0, 0, 0]},
    {name: "Розважливий Рішучий", value: [1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1]},
    {name: "Веселий Серьозний", value: [1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0]},
    //dual
    {name: "Безпечний Передбачливий", value: [1, 1, 0, 0, 1, 1, 0, 0, 0, 0, 1, 1, 0, 0, 1, 1]},
    {name: "Поступливий Упертий", value: [1, 1, 0, 0, 0, 0, 1, 1, 0, 0, 1, 1, 1, 1, 0, 0]},
    {name: "Ірраціонал Раціонал", value: [1, 1, 0, 0, 0, 0, 1, 1, 1, 1, 0, 0, 0, 0, 1, 1]},
    //indiv
    {name: "Квестім Деклатім", value: [1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1]},
    {name: "Позитивіст Негативіст", value: [1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1]},
    {name: "Тактик Стратег", value: [1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0]},
    {name: "Конструктивіст Емотивіст", value: [1, 0, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 0, 1]},
    //dyal
    {name: "Процесор Результатер", value: [1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0]}
];

let types = [
    {id: 0, code: "ІЛЕ", name: "Шукач"},
    {id: 1, code: "СЕІ", name: "Посередник"},
    {id: 2, code: "ЕСЕ", name: "Ентузіаст"},
    {id: 3, code: "ЛІІ", name: "Аналітик"},
    {id: 4, code: "ЕІЕ", name: "Наставник"},
    {id: 5, code: "ЛСІ", name: "Інспектор"},
    {id: 6, code: "СЛЕ", name: "Маршал"},
    {id: 7, code: "ІЕІ", name: "Лірик"},
    {id: 8, code: "СЕЕ", name: "Політик"},
    {id: 9, code: "ІЛІ", name: "Критик"},
    {id: 10, code: "ЛІЕ", name: "Підприємець"},
    {id: 11, code: "ЕСІ", name: "Хранитель"},
    {id: 12, code: "ЛСЕ", name: "Адміністратор"},
    {id: 13, code: "ЕІІ", name: "Гуманіст"},
    {id: 14, code: "ІЕЕ", name: "Порадник"},
    {id: 15, code: "СЛІ", name: "Майстер"}
];

export { aspects, functions, reinins, types };