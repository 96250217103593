
const contentType = {aspects: "aspects", functions: "functions", reinins: "reinins"};
const contentList = {all: "all", correct: "correct", wrong: "wrong"};
const log = false;

const compareNumbers = (a, b) => {
    return a - b;
};


export {contentList, contentType, log, compareNumbers};